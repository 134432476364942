<template>
   <v-expand-transition>
      <v-form ref="form">
         <div v-if="typeof option.id === 'string' || option.id >= 0">
            <v-card-text class="pt-9">
               <v-row>
                  <v-col v-if="$store.state.user.code === 'SPA'" cols="12" md="3">
                     <v-select v-model="form.zone" :items="zones" label="Management Zone" placeholder="Zona Manajemen" outlined hide-details required :rules="[v => !!v]"
                        item-text="name" item-value="zone"
                        :return-object="false" :menu-props="{ bottom: true, offsetY: true }"
                        />
                  </v-col>
               </v-row>
               <v-row>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.smtp" label="SMTP Host" placeholder="SMTP Host" outlined hide-details>
                        <template slot="append-outer">
                           <v-text-field v-model="form.port" label="Port" placeholder="Port" outlined hide-details class="text-align-right" style="width: 80px;" />
                        </template>
                     </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.alias" label="Sender Name" placeholder="Nama Pengirim" outlined hide-details />
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.email" label="Email" placeholder="Surel" outlined hide-details />
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.password" label="Password" placeholder="Kata Sandi" type="password" outlined hide-details />
                  </v-col>
               </v-row>
               <v-row class="mt-3 mx-n4 mb-5">
                  <v-divider />
               </v-row>
               <v-row>
                  <v-col cols="12" md="4">
                     <v-text-field v-model="form.telegram" label="Telegram Bot Token" placeholder="Token Bot Telegram" outlined hide-details />
                     <!-- <v-switch hide-details class="mt-0 pt-0">
                        <template v-slot:label>
                           Use Telegram
                           <v-text-field outlined hide-details class="ml-3" />
                        </template>
                     </v-switch> -->
                  </v-col>
                  <v-col v-if="isUsed" cols="12" md="4">
                     <v-text-field v-model="form.whatsapp" label="WhatsApp Bot Token" placeholder="Token Bot Telegram" outlined hide-details />
                  </v-col>
               </v-row>
            </v-card-text>
            <v-divider />
            <v-card-text class="pb-6">
               <v-row class="mt-0">
                  <v-col cols="12" class="py-0 d-flex">
                     <v-btn v-if="$hasRole(option.kind.toLowerCase(), 'CU')" x-large color="accent" depressed class="mr-4" :loading="$store.state.process === 'updateOption'"
                        :disabled="!((form.email && form.password) || form.telegram || form.whatsapp)"
                        @click="updateRecord"
                        >
                        Update
                     </v-btn>
                     <v-btn v-if="$store.state.user.code === 'SPA'" x-large color="accent" depressed outlined class="mr-4" @click="option.id = -1">
                        {{ $store.state.process === 'updateOption' ? 'Close' : 'Cancel' }}
                     </v-btn>
                     <v-spacer />
                  </v-col>
               </v-row>
            </v-card-text>
            <v-divider />
         </div>
      </v-form>
   </v-expand-transition>
</template>

<script>
import { encrypt } from '@/utils/nirtara'
import { } from '@/utils/fields.js'
const empty = { kind: 'Notification', zone: '', alias: '', smtp: '', port: '', email: '', password: '', telegram: '', whatsapp: '' }
export default {
   name: 'NNotificationForm',

   props: {
      option: { type: Object },
      zones: { type: Array },
   },
   data: () => ({
      isUsed: false,
      form: Object.assign({}, empty),
   }),

   computed: {
   },
   watch: {
      'option.id' (id) {
         if (id < 0) return
         id === 0 && this.$refs.form.reset()
         this.form = Object.assign({}, empty, typeof id === 'string' ? this.option : { id: 0 })
      },
   },
   mounted () {
      Object.assign(this.form, this.option, { password: encrypt(this.option.password || '') })
   },
   methods: {
      updateRecord () {
         if (!this.$refs.form.validate()) return
         this.$emit('update', this.form)
      },
   },
}
</script>

<style lang="scss" scoped>
.col-12 {
   padding: 0 12px 20px 12px;
}
::v-deep .text-align-right .v-text-field__slot input {
   text-align: right;
}
::v-deep .v-text-field--outlined .v-input__append-outer {
   margin: auto 0 auto 16px;
}
.v-btn:not(.v-btn--round).v-size--x-large {
   min-width: 115px;
}
</style>
